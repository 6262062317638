import StudyLesson from "../components/StudyLesson";
import StudyPlanTopic from "../components/StudyPlanTopic";

function renderFeature(title, description, image, color) {
    return (
        <div className={`rounded-xl h-full flex flex-col`}>
            <div className={`rounded-3xl ${color} relative w-full`} style={{ paddingTop: "50%" }}>
                <img src={image} alt={title} className="rounded-xl absolute top-0 left-0 w-full h-full object-cover" />
            </div>
            <div className="flex justify-center w-full py-8">
                <div className="w-2/3 text-center">
                    <h3 className="text-2xl font-bold pb-4">{title}</h3>
                    <div className="text-lg">{description}</div>
                </div>
            </div>
        </div>
    );
}

function renderStudentFeatures() {
    const features = [
        {
            title: "Personalized study plans",
            description: "Follow your curiosity and learn more efficiently with AI-powered study plans tailored to your goals, interests, and needs.",
            image: "/www/study-plan.png",
            color: "bg-pastelblue"
        },
        {
            title: "Engaging content",
            description: "Explore a wide range of educational YouTube videos across different subjects and styles, making learning more fun and relevant to you.",
            image: "/www/video.png",
            color: "bg-pastelyellow"
        },
        {
            title: "Deeper comprehension",
            description: "Get AI-generated video summaries and interactive quizzes that reinforce key concepts, turning video watching into an active learning experience.",
            image: "/www/quiz.png",
            color: "bg-pastelgreen"
        }
    ];

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {features.map((feature) => (
                <div key={feature.title}>
                    {renderFeature(feature.title, feature.description, feature.image, feature.color)}
                </div>
            ))}
        </div>
    );
}

function renderParentFeatures() {
    const features = [
        {
            title: "Personalized study plans",
            description: "Support your child's learning journey with AI-powered study plans tailored to their interests and goals. Easily create or review their roadmap to success.",
            image: "/www/study-plan-parent.png",
            color: "bg-pastelpurple"
        },
        {
            title: "Track progress",
            description: "Stay informed with real-time progress tracking and daily email updates. With AI-generated learning summaries, you can effortlessly stay engaged with your children's education.",
            image: "/www/progress.png",
            color: "bg-pastelblue"
        },
        {
            title: "Multiple child accounts",
            description: "Manage study plans for the whole family with individual child accounts, ensuring each child gets a personalized learning experience.",
            image: "/www/bg-water-top-left7.png",
            color: "bg-pastelblue"
        },
    ];

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {features.map((feature) => (
                <div key={feature.title}>
                    {renderFeature(feature.title, feature.description, feature.image, feature.color)}
                </div>
            ))}
        </div>
    );
}

function renderStudyPlanCard(subject, description, image, color = "bg-pastelblue") {
    return (
        <div className="flex items-center relative h-full">
            {/* Circular emoji container on the left */}
            <div className="relative z-10">
                <div className={`rounded-full overflow-hidden w-20 h-20 border-2 border-white ${color} flex items-center justify-center`}>
                    <span className="text-4xl" aria-label={subject}>{image}</span>
                </div>
            </div>

            {/* Text container that overlaps with the image */}
            <div className={`bg-gray-200 rounded-2xl p-4 pl-12 pb-6 ml-[-2rem] flex-1 text-left h-full flex flex-col justify-between`}>
                <div>
                    <h3 className="text-md font-bold">{subject}</h3>
                    <p className="mb-3">{description}</p>
                </div>

                <div className="mt-auto pt-2">
                    <button
                        id={`${subject}-card-button`}
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        onClick={(e) => {
                            e.preventDefault();
                            const modal = document.getElementById(`${subject}-details`);
                            modal.style.display = 'flex';
                        }}
                    >
                        View study plan
                    </button>
                </div>
            </div>
        </div>
    );
}

function renderStudyPlanDetails(studyPlan) {
    return (
        <div id={`${studyPlan.subject}-details`} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 hidden">
            <div className="bg-white p-6 sm:p-12 rounded-lg shadow-lg w-9/10 md:w-2/3 h-4/5 relative">
                <button
                    id={`${studyPlan.subject}-details-close-button`}
                    className="absolute top-4 right-6 text-gray-500 hover:text-gray-800 text-2xl"
                    onClick={() => {
                        document.getElementById(`${studyPlan.subject}-details`).style.display = 'none';
                    }}
                >
                    x
                </button>
                <div className="text-left h-full w-full flex flex-col">
                    <div className="overflow-y-auto flex-grow">
                        <h3 className="text-2xl font-semibold">{studyPlan.image} {studyPlan.subject}</h3>
                        <div className="text-lg">{studyPlan.description}</div>
                        <div className="flex justify-end mb-4">
                            <button
                                id={`${studyPlan.subject}-details-more-button`}
                                className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 rounded text-sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const topicContainers = document.getElementsByClassName(`${studyPlan.subject}-topics`);
                                    const isHidden = topicContainers[0]?.style.display === 'none';
                                    Array.from(topicContainers).forEach(container => {
                                        container.style.display = isHidden ? 'block' : 'none';
                                    });
                                    e.target.textContent = isHidden ? 'Hide Details' : 'Show Details';
                                }}
                            >
                                Show Details
                            </button>
                        </div>
                        {studyPlan.lessons.map((lesson, index) => (
                            <div key={lesson.title}>
                                <StudyLesson lesson={lesson} index={index} isLastLesson={index === studyPlan.lessons.length - 1} onLessonClick={() => { }}>
                                    <div className={`${studyPlan.subject}-topics`} style={{ display: 'none' }}>
                                        {lesson.topics.map((topic, topicIndex) => (
                                            <StudyPlanTopic topic={topic} index={topicIndex} />
                                        ))}
                                    </div>
                                </StudyLesson>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function renderStudyPlans() {
    const studyPlans = [
        {
            subject: "Math",
            description: "Master geometry and trigonometry essentials, including relationships between angles and sides",
            image: "📐",
            color: "bg-pastelgreen",
            lessons: [
                {
                    explanation: "Introduce the concept of quadratic equations, teaching students how to identify them and explore their standard form. Explain the role of coefficients and constants in shaping the parabola, highlighting how equations are solved by factoring when possible.",
                    title: "Quadratic Equations Basics",
                    topics: [
                        {
                            title: "Understanding the Standard Form of Quadratic Equations"
                        },
                        {
                            title: "Exploring the Roles of Coefficients in Quadratics"
                        },
                        {
                            title: "Identifying Solutions through Factoring"
                        }
                    ]
                },
                {
                    explanation: "Focus on various methods of factoring quadratic expressions, including recognizing perfect squares, the difference of squares, and trinomials. Guide students through systematic practice of these techniques to develop fluency in breaking down expressions.",
                    title: "Factoring Techniques",
                    topics: [
                        {
                            title: "Factoring Perfect Square Trinomials"
                        },
                        {
                            title: "Recognizing and Factoring the Difference of Squares"
                        },
                        {
                            title: "Mastering Factoring by Grouping"
                        }
                    ]
                },
                {
                    explanation: "Present the foundational concepts of polynomials by explaining terms, degree, and leading coefficients. Teach students how to perform operations, such as addition, subtraction, and multiplication on polynomials, setting the stage for more advanced manipulation.",
                    title: "Introduction to Polynomials",
                    topics: [
                        {
                            title: "Defining Terms and Degrees in Polynomials"
                        },
                        {
                            title: "Performing Addition on Polynomials"
                        },
                        {
                            title: "Multiplying Polynomials"
                        }
                    ]
                },
                {
                    explanation: "Help students understand linear inequalities and learn methods for solving them. Teach the principles of graphing solutions on a number line, and introduce the concept of representing solutions on the coordinate plane for two-variable inequalities.",
                    title: "Linear Inequalities and Graphing",
                    topics: [
                        {
                            title: "Solving Single-Variable Linear Inequalities"
                        },
                        {
                            title: "Graphing Solutions of Inequalities on a Number Line"
                        },
                        {
                            title: "Understanding Two-Variable Inequalities on the Coordinate Plane"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Science",
            description: "Understand the weather system, including atmospheric pressure, the water cycle, and climate zones",
            image: "🌤️",
            color: "bg-pastelblue",
            lessons: [
                {
                    explanation: "Explore atmospheric pressure, its measurement, and its influence on wind patterns, including global wind belts and local winds.",
                    title: "Atmospheric Pressure and Winds",
                    topics: [
                        {
                            title: "Introduction to Atmospheric Pressure"
                        },
                        {
                            title: "Measuring Atmospheric Pressure with Barometers"
                        },
                        {
                            title: "Global Wind Belts: Trade Winds, Westerlies, and Polar Easterlies"
                        }
                    ]
                },
                {
                    explanation: "Investigate the continuous movement of water on, above, and below the surface of the Earth and its impact on various weather phenomena such as precipitation and cloud formation.",
                    title: "The Water Cycle and Weather Patterns",
                    topics: [
                        {
                            title: "Evaporation, Condensation, and Precipitation Processes"
                        },
                        {
                            title: "The Role of Clouds in the Water Cycle"
                        },
                        {
                            title: "Different Types of Precipitation: Rain, Snow, Sleet, and Hail"
                        }
                    ]
                },
                {
                    explanation: "Examine the different climate zones around the world, analyzing the factors that affect climate, such as latitude, altitude, and proximity to bodies of water.",
                    title: "Climate Zones and Factors Affecting Climate",
                    topics: [
                        {
                            title: "Major Climate Zones: Tropical, Temperate, and Polar"
                        },
                        {
                            title: "Impact of Latitude on Temperature and Climate"
                        },
                        {
                            title: "Influence of Ocean Currents on Regional Climates"
                        }
                    ]
                }
            ],
        },
        {
            subject: "English",
            description: "Learn creative writing fundamentals such as plot, character development, and descriptive language",
            image: "🖋️",
            color: "bg-pastelyellow",
            lessons: [
                {
                    explanation: "Introduce the fundamental elements of plot \u2013 exposition, rising action, climax, falling action, and resolution \u2013 and explore how they work together to create a compelling story.",
                    title: "Plotting the Course",
                    topics: [
                        {
                            title: "Understanding Exposition and Setting the Scene"
                        },
                        {
                            title: "Rising Action and Building Suspense"
                        },
                        {
                            title: "Climax, Falling Action, and Resolution: Defining the Core of the Story"
                        }
                    ]
                },
                {
                    explanation: "Define character development and explore techniques for creating realistic and engaging characters through internal and external traits, motivations, and relationships with other characters.",
                    title: "Building Believable Characters",
                    topics: [
                        {
                            title: "Internal and External Character Traits"
                        },
                        {
                            title: "Character Motivation and Goals"
                        },
                        {
                            title: "Character Relationships and Dynamics"
                        }
                    ]
                },
                {
                    explanation: "Explore the power of descriptive language, including similes, metaphors, personification, and sensory details, to create vivid images and enhance writing.",
                    title: "Painting with Words Descriptive Language",
                    topics: [
                        {
                            title: "Similes and Metaphors: Comparing and Contrasting"
                        },
                        {
                            title: "Personification: Giving Human Qualities"
                        },
                        {
                            title: "Sensory Details: Appealing to the Senses"
                        }
                    ]
                },
                {
                    explanation: "Combine the elements of plot, character, and descriptive language to brainstorm, outline, and begin drafting a short story, focusing on creating a cohesive and engaging narrative.",
                    title: "Crafting a Story",
                    topics: [
                        {
                            title: "Brainstorming Story Ideas and Concepts"
                        },
                        {
                            title: "Outlining the Plot Structure"
                        }
                    ]
                }
            ],
        },
        {
            subject: "History",
            description: "Examine the history of ancient Rome, including the Roman Republic and the Roman Empire",
            image: "🏛️",
            color: "bg-pastelpurple",
            lessons: [
                {
                    explanation: "Explore the founding and structure of the Roman Republic, including its key institutions, social classes, and expansion.",
                    title: "The Roman Republic",
                    topics: [
                        {
                            title: "Founding of the Roman Republic and the Role of Patricians and Plebeians"
                        },
                        {
                            title: "Structure of the Roman Republic: Senate, Consuls, and Assemblies"
                        },
                        {
                            title: "Territorial Expansion and Military Campaigns of the Roman Republic"
                        }
                    ]
                },
                {
                    explanation: "Investigate the transition from Republic to Empire, the reign of prominent emperors, and the factors that contributed to the decline and eventual fall of the Western Roman Empire.",
                    title: "The Rise and Fall of the Roman Empire",
                    topics: [
                        {
                            title: "Key Events in the Transformation from Republic to Empire"
                        },
                        {
                            title: "Significant Reigns: Emperors Augustus, Nero, and Constantine"
                        },
                        {
                            title: "Internal and External Factors in the Fall of the Western Roman Empire"
                        }
                    ]
                },
                {
                    explanation: "Examine daily life, social structures, cultural achievements, and the lasting legacy of Roman civilization, including its contributions to law, language, architecture, and engineering.",
                    title: "Roman Society and Culture",
                    topics: [
                        {
                            title: "Daily Life in Ancient Rome: Family, Housing, and Entertainment"
                        },
                        {
                            title: "Social Hierarchies and the Role of Slavery in Roman Society"
                        },
                        {
                            title: "Architectural and Engineering Feats: Aqueducts, Roads, and the Colosseum"
                        },
                        {
                            title: "The Influence of Roman Law and Language on Western Civilization"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Chess",
            description: "Master chess opening strategies, such as the Sicilian Defense and the English Opening",
            image: "♟️",
            color: "bg-pastelgreen",
            lessons: [
                {
                    explanation: "Delve into the basic principles and moves of the Sicilian Defense, focusing on its primary variations and typical pawn structures. Understand why it is one of the most popular responses to 1.e4 and learn how to control the center while counter-attacking.",
                    title: "Sicilian Defense Fundamentals",
                    topics: [
                        {
                            title: "Structure and Goals of the Sicilian Defense"
                        },
                        {
                            title: "Key Variations: Dragon, Najdorf, and Scheveningen"
                        },
                        {
                            title: "The Role of Open Sicilian vs. Closed Sicilian"
                        }
                    ]
                },
                {
                    explanation: "Explore common traps and tactical motifs encountered in the Sicilian Defense. Recognize common errors from both sides and learn strategies to capitalize on opponents' mistakes during this opening.",
                    title: "Common Traps in the Sicilian Defense",
                    topics: [
                        {
                            title: "The Poisoned Pawn Variation and Its Risks"
                        },
                        {
                            title: "Recognizing the Pin and Fork Tactics"
                        },
                        {
                            title: "Preventing Common Opening Blunders"
                        }
                    ]
                },
                {
                    explanation: "Introduce the English Opening by examining its typical pawn structures and move sequences. Emphasize controlling the center through flanking pieces and preparing for a flexible and strategic middle game.",
                    title: "English Opening Fundamentals",
                    topics: [
                        {
                            title: "Typical Pawn Advances and Their Strategic Impact"
                        },
                        {
                            title: "Controlling Key Squares with C4 and G3 Pawns"
                        },
                        {
                            title: "The Reversed Sicilian in the English Opening"
                        }
                    ]
                },
                {
                    explanation: "Analyze the importance of knights and bishops when playing the English Opening. Understand their optimal placement and how they can be effectively utilized for coordinating attacks and defense.",
                    title: "Knights and Bishops in the English Opening",
                    topics: [
                        {
                            title: "Strategic Knight Moves: Target Squares"
                        },
                        {
                            title: "Fianchetto of Bishops: Pros and Cons"
                        },
                        {
                            title: "Transitioning Knights and Bishops from Opening to Middlegame"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Robotics",
            description: "Learn the basics of Arduino Uno programming, including the use of sensors and actuators",
            image: "🤖",
            color: "bg-pastelblue",
            lessons: [
                {
                    explanation: "Begin with an introduction to the Arduino Uno, familiarizing students with its components and basic functions. Explain the significance of Arduino in robotics and simple electronics.",
                    title: "Understanding Arduino Uno",
                    topics: [
                        {
                            title: "Components of the Arduino Uno board"
                        },
                        {
                            title: "Basic functions of the Arduino Uno"
                        },
                        {
                            title: "Importance of Arduino in robotics"
                        },
                        {
                            title: "Simple electronics concepts with Arduino"
                        }
                    ]
                },
                {
                    explanation: "Introduce students to Arduino programming using the Arduino Integrated Development Environment (IDE). Walk through setting up the IDE, writing a simple program, and uploading it to the Arduino Uno board.",
                    title: "Basic Arduino Programming",
                    topics: [
                        {
                            title: "Setting up the Arduino IDE"
                        },
                        {
                            title: "Writing a simple Arduino program"
                        },
                        {
                            title: "Uploading programs to the Arduino Uno"
                        }
                    ]
                },
                {
                    explanation: "Explore the concept of sensors and their role in robotics. Teach students how to connect and program a basic sensor, such as a light or temperature sensor, to the Arduino Uno, including reading sensor data.",
                    title: "Introduction to Sensors",
                    topics: [
                        {
                            title: "Role of sensors in robotics"
                        },
                        {
                            title: "Connecting sensors to Arduino Uno"
                        },
                        {
                            title: "Programming a light sensor"
                        },
                        {
                            title: "Reading and interpreting sensor data"
                        }
                    ]
                },
                {
                    explanation: "Delve into actuators, explaining their function and how they differ from sensors. Guide students in programming a simple actuator, like a servo motor or an LED, demonstrating how it can be controlled by the Arduino Uno using sensor input.",
                    title: "Working with Actuators",
                    topics: [
                        {
                            title: "Function of actuators"
                        },
                        {
                            title: "Difference between sensors and actuators"
                        },
                        {
                            title: "Programming a servo motor"
                        },
                        {
                            title: "Using sensor input to control actuators"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Finance",
            description: "Understand personal finance basics, including budgeting, saving, and investing",
            image: "💰",
            color: "bg-pastelyellow",
            lessons: [
                {
                    explanation: "Learn the importance of budgeting and how to create a simple budget to track income and expenses.",
                    title: "Budgeting 101",
                    topics: [
                        {
                            title: "Identifying Sources of Income"
                        },
                        {
                            title: "Categorizing Expenses"
                        },
                        {
                            title: "Balancing Income and Expenses"
                        }
                    ]
                },
                {
                    explanation: "Discover different saving strategies and the power of compound interest to reach financial goals.",
                    title: "Saving Strategies",
                    topics: [
                        {
                            title: "Setting Savings Goals"
                        },
                        {
                            title: "Understanding Interest Rates"
                        },
                        {
                            title: "Identifying Savings Accounts Types"
                        }
                    ]
                },
                {
                    explanation: "Explore basic investment options like stocks and bonds and understand the concept of risk and return.",
                    title: "Introduction to Investing",
                    topics: [
                        {
                            title: "Defining Stocks and Bonds"
                        },
                        {
                            title: "Analyzing Risk and Reward"
                        },
                        {
                            title: "Understanding Diversification"
                        }
                    ]
                },
                {
                    explanation: "Understand the importance of setting financial goals and creating a plan to achieve them, including college savings and career planning.",
                    title: "Financial Planning for the Future",
                    topics: [
                        {
                            title: "Setting Short-term vs Long-term Goals"
                        },
                        {
                            title: "Creating a College Savings Plan"
                        },
                        {
                            title: "Exploring Career Opportunities and Salaries"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Design",
            description: "Master principles of visual design, including color theory, typography, and layout",
            image: "🎨",
            color: "bg-pastelpurple",
            lessons: [
                {
                    explanation: "Introduce students to the basics of color theory, including the color wheel, primary, secondary, and tertiary colors. Explore the concepts of warm and cool colors, complementary colors, and the psychological impact of color.",
                    title: "Color Theory Fundamentals",
                    topics: [
                        {
                            title: "Understanding the Color Wheel and Color Relationships"
                        },
                        {
                            title: "Identifying Primary, Secondary, and Tertiary Colors"
                        },
                        {
                            title: "Warm and Cool Colors and Their Effects"
                        }
                    ]
                },
                {
                    explanation: "Teach students about different types of fonts and their uses. Discuss the anatomy of type, including terms such as serif, sans-serif, leading, kerning, and tracking, and how these elements contribute to readability and visual appeal.",
                    title: "Typography Basics",
                    topics: [
                        {
                            title: "Different Types of Fonts and Their Appropriate Uses"
                        },
                        {
                            title: "Understanding Serif and Sans-serif Features"
                        },
                        {
                            title: "Enhancing Readability through Typographic Elements"
                        }
                    ]
                },
                {
                    explanation: "Explore essential principles of layout design, such as balance, alignment, repetition, contrast, and proximity. Students will learn how to arrange visual elements in a cohesive manner to create aesthetically pleasing and effective designs.",
                    title: "Elements of Layout Design",
                    topics: [
                        {
                            title: "Principles of Balance and Alignment in Layout Design"
                        },
                        {
                            title: "Utilizing Repetition and Contrast for Visual Interest"
                        },
                        {
                            title: "Proximity and Its Role in Element Grouping"
                        },
                        {
                            title: "Cohesive Arrangement Techniques for Effective Layouts"
                        }
                    ]
                },
                {
                    explanation: "Guide students in integrating color theory, typography, and layout principles to create a unified design project. Emphasize the importance of consistency and harmony in visual design and encourage creative application of these principles.",
                    title: "Combining Design Principles",
                    topics: [
                        {
                            title: "Integrating Color Theory into Unified Designs"
                        },
                        {
                            title: "Application of Typography Principles in Project Design"
                        },
                        {
                            title: "Harmonizing Layout Elements for Consistent Design Output"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Personal Development",
            description: "Master basic personal development skills, focusing on self-awareness, emotional regulation, and goal settings",
            image: "💪",
            color: "bg-pastelgreen",
            lessons: [
                {
                  explanation: "Discover different emotions, learning to identify and understand feelings, and the importance of emotional expression.",
                  title: "Understanding Emotions",
                  topics: [
                    {
                      title: "Different Types of Emotions"
                    },
                    {
                      title: "Identifying Personal Emotions"
                    },
                    {
                      title: "Benefits of Emotional Expression"
                    }
                  ]
                },
                {
                  explanation: "Explore practical emotional regulation techniques, including deep breathing, mindfulness, and positive self-talk for handling challenging emotions.",
                  title: "Emotional Regulation Techniques",
                  topics: [
                    {
                      title: "Introduction to Deep Breathing"
                    },
                    {
                      title: "Basics of Mindfulness"
                    },
                    {
                      title: "Using Positive Self-Talk"
                    }
                  ]
                },
                {
                  explanation: "Learn the basics of effective goal setting, covering the importance of setting achievable and relevant goals for personal growth.",
                  title: "Goal-Setting Basics",
                  topics: [
                    {
                      title: "Defining Achievable Goals"
                    },
                    {
                      title: "Relevance of Setting Personal Goals"
                    },
                    {
                      title: "Steps for Effective Goal Setting"
                    }
                  ]
                },
                {
                  explanation: "Understand resilience and its importance, learning strategies to bounce back from setbacks and develop a growth mindset.",
                  title: "Building Resilience",
                  topics: [
                    {
                      title: "Concepts of Resilience"
                    },
                    {
                      title: "Strategies to Overcome Setbacks"
                    },
                    {
                      title: "Developing a Growth Mindset"
                    }
                  ]
                }
            ],
        },
        {
            subject: "Chemistry",
            description: "Explore the science of coffee chemistry, including the effects of caffeine on the human body",
            image: "☕️",
            color: "bg-pastelblue",
            lessons: [
                {
                    explanation: "Introduce students to the chemical compounds found in coffee, focusing on caffeine. Explore how caffeine is structured on a molecular level and compare it with other molecules they have learned about.",
                    title: "Coffee Chemistry Basics",
                    topics: [
                        {
                            title: "Molecular Structure of Caffeine"
                        },
                        {
                            title: "Chemical Compounds in Coffee"
                        },
                        {
                            title: "Comparison of Caffeine with Similar Molecules"
                        }
                    ]
                },
                {
                    explanation: "Examine how the process of brewing coffee affects its chemical composition. Discuss factors like temperature and time, and how they influence the extraction of chemical compounds, including caffeine, from coffee beans.",
                    title: "The Chemistry of Brewing",
                    topics: [
                        {
                            title: "Influence of Temperature on Coffee Brewing"
                        },
                        {
                            title: "Role of Brewing Time in Chemical Extraction"
                        },
                        {
                            title: "Chemical Changes During Coffee Brewing"
                        }
                    ]
                },
                {
                    explanation: "Investigate how caffeine interacts with human biology. Discuss its effects on the nervous system, how it can boost energy levels, and potential health implications of caffeine consumption.",
                    title: "Caffeine Effects on the Human Body",
                    topics: [
                        {
                            title: "Caffeine's Interaction with the Nervous System"
                        },
                        {
                            title: "Energy Level Boost from Caffeine"
                        },
                        {
                            title: "Health Implications of Caffeine Consumption"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Gardening",
            description: "Learn the basics of backyard farming, such as choosing the right plants and soil management",
            image: "🌾",
            color: "bg-pastelyellow",
            lessons: [
                {
                    explanation: "Understand the fundamentals of backyard farming, including its benefits, planning considerations, and initial steps for creating a successful garden.",
                    title: "Backyard Farming An Introduction",
                    topics: [
                        {
                            title: "Benefits of Backyard Farming"
                        },
                        {
                            title: "Planning Your Backyard Farm: Location, Size, and Sunlight"
                        },
                        {
                            title: "Essential Tools and Initial Setup"
                        }
                    ]
                },
                {
                    explanation: "Learn about the composition of soil, different soil types, and the importance of soil health for plant growth, including testing and amendment techniques.",
                    title: "Soil Science",
                    topics: [
                        {
                            title: "Soil Composition: Minerals, Organic Matter, Water, and Air"
                        },
                        {
                            title: "Soil Types: Sand, Silt, Clay, and Loam"
                        },
                        {
                            title: "Soil Testing Methods and Interpretation"
                        }
                    ]
                },
                {
                    explanation: "Explore various plant families suitable for backyard gardens, focusing on factors influencing plant choice such as climate, space, and personal preferences, to create a crop plan.",
                    title: "Plant Selection and Crop Planning",
                    topics: [
                        {
                            title: "Understanding Plant Families and their Characteristics"
                        },
                        {
                            title: "Climate Considerations: Hardiness Zones and Microclimates"
                        },
                        {
                            title: "Crop Rotation Principles and Companion Planting"
                        }
                    ]
                },
                {
                    explanation: "Learn proper planting techniques, watering strategies, fertilization methods, and basic pest and disease management for maintaining a thriving backyard garden.",
                    title: "Planting and Maintenance",
                    topics: [
                        {
                            title: "Seed Starting vs. Direct Sowing Techniques"
                        },
                        {
                            title: "Watering Techniques: Frequency, Depth, and Efficiency"
                        },
                        {
                            title: "Natural Pest and Disease Control Methods"
                        }
                    ]
                }
            ],
        },
        {
            subject: "Marketing",
            description: "Learn digital marketing strategies, including SEO, SEM, and social media marketing",
            image: "💻",
            color: "bg-pastelpurple",
            lessons: [
                {
                    explanation: "Define marketing and digital marketing, and discuss why digital marketing is important for businesses today.",
                    title: "Digital Marketing Introduction",
                    topics: [
                        {
                            title: "Definition of Marketing Concepts"
                        },
                        {
                            title: "Differences Between Traditional and Digital Marketing"
                        },
                        {
                            title: "Importance of Digital Marketing in the Modern Age"
                        }
                    ]
                },
                {
                    explanation: "Explain what SEO is and how it helps websites rank higher in search engine results, and introduce basic SEO concepts like keywords and content optimization.",
                    title: "Search Engine Optimization Basics",
                    topics: [
                        {
                            title: "Understanding Search Engines and Their Role"
                        },
                        {
                            title: "On-Page SEO Techniques"
                        },
                        {
                            title: "Utilization of Keywords in SEO"
                        },
                        {
                            title: "Basics of Content Optimization for SEO"
                        }
                    ]
                },
                {
                    explanation: "Explain what SEM is and how it works, focusing on pay-per-click advertising and creating effective ad campaigns.",
                    title: "Search Engine Marketing Introduction",
                    topics: [
                        {
                            title: "Components of Search Engine Marketing"
                        },
                        {
                            title: "Introduction to Pay-Per-Click (PPC) Advertising"
                        },
                        {
                            title: "Crafting Effective Ad Campaigns"
                        },
                        {
                            title: "Metrics and Analysis in SEM"
                        }
                    ]
                },
                {
                    explanation: "Discuss various social media platforms and how businesses can use them to reach their target audience, covering content creation, engagement, and social media advertising.",
                    title: "Social Media Marketing Strategies",
                    topics: [
                        {
                            title: "Overview of Popular Social Media Platforms"
                        },
                        {
                            title: "Creating Engaging Social Media Content"
                        },
                        {
                            title: "Building Audience Engagement on Social Media"
                        },
                        {
                            title: "Basics of Social Media Advertising"
                        }
                    ]
                }
            ],
        },
    ];
    return (
        <>
            {
                studyPlans.map((studyPlan) => (
                    <>
                        {studyPlan.lessons && renderStudyPlanDetails(studyPlan)}
                    </>
                ))
            }
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
                {studyPlans.map((studyPlan, index) => (
                    <div key={index}>
                        {renderStudyPlanCard(studyPlan.subject, studyPlan.description, studyPlan.image, studyPlan.color)}
                    </div>
                ))}
            </div>
        </>
    );
}

function IndexPage() {
    return (
        <div className="flex flex-col gap-12">
            <div className="flex flex-col items-center justify-center text-center gap-12">
                <h2 className="text-4xl">Student Experience</h2>
                {renderStudentFeatures()}
            </div>

            <div className="flex flex-col items-center justify-center text-center gap-12">
                <h2 className="text-4xl">Parent Experience</h2>
                {renderParentFeatures()}
            </div>

            <div className="flex flex-col items-center justify-center text-center gap-12">
                <h2 className="text-4xl">Pursue your curiosity</h2>

                {renderStudyPlans()}
            </div>
        </div>
    );
}

export default IndexPage;
