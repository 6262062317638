import React from 'react';
import LoadingButton from './LoadingButton';

function VerificationCode({ email, token, onTokenChange, onResend, isLoading, error, onSubmit }) {
    return (
        <div>
            <div className="flex flex-col text-sm mb-5 gap-2 sm:gap-0">
                <div>Verification code sent to {email}.</div>
                <div>
                    Check the spam folder if you haven't received it, 
                    or <span className="text-blue-500 underline cursor-pointer" onClick={onResend}>resend</span>.
                </div>
            </div>
            <input
                type="text"
                className="border border-gray-300 p-1 my-1"
                pattern="\d{6}" 
                maxLength="6" 
                inputMode="numeric"
                placeholder="Verification code"
                value={token}
                onChange={(e) => onTokenChange(e.target.value)} />
            
            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
            
            <div className="flex justify-center w-full">
                <LoadingButton
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded my-3"
                    text="Continue"
                    isLoading={isLoading}
                    onClick={onSubmit} />
            </div>
        </div>
    );
}

export default VerificationCode; 