import axios from 'axios';

const apiUrl = process.env.REACT_APP_LF_API_URL || 'http://localhost:5001';
const defaultTimeout = 20000;

axios.defaults.withCredentials = true;

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

function _createClient(timeout) {
  return axios.create({
    baseURL: apiUrl,
    timeout: timeout,
  });
}

export async function api_get(path, params, setError = null, timeout = defaultTimeout) {
  try {
    return await _createClient(timeout).get(path, { params: params });
  }
  catch (error) {
    console.error(error);
    if (setError && error.response && error.response.data && error.response.data.errors) {
      setError(error.response.data.errors[0]);
    }
    return null;
  }
};

export async function api_post(path, params, setError = null, timeout = defaultTimeout) {
  try {
    return await _createClient(timeout).post(path, params);
  }
  catch (error) {
    console.error(error);
    if (setError && error.response && error.response.data && error.response.data.errors) {
      setError(error.response.data.errors[0]);
    }
    return null;
  }
};

export async function api_delete(path, params, setError = null, timeout = defaultTimeout) {
  try {
    return await _createClient(timeout).delete(path, { params: params });
  }
  catch (error) {
    console.error(error);
    if (setError && error.response && error.response.data && error.response.data.errors) {
      setError(error.response.data.errors[0]);
    }
    return null;
  }
};

export default axios.create({
  baseURL: apiUrl,
  timeout: defaultTimeout,
});
