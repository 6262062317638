import React from 'react';
import StudyPlanTopic from './StudyPlanTopic';
import { donutChart } from '../api/donutChart';

const StudyPlanLesson = ({ 
    lesson, 
    lessonIndex, 
    currentLessonIndex,
    currentTopicIndex,
    completedVideosNum,
    onLessonClick,
    onTopicClick,
    isLastLesson
}) => {
    let lessonColor;
    let boxStyle;
    let arrowColor;
    let nextLessonEnabled;
    let nextLessonStyle;
    let topicType;
    let topicStyle;

    if (onLessonClick === null) {
        lessonColor = 'text-black';
        boxStyle = 'bg-white border border-black';
        arrowColor = 'border-t-black';
        topicType = 'normal';
        topicStyle = '';
    } else if (lessonIndex < currentLessonIndex) {
        lessonColor = 'text-gray-500';
        boxStyle = 'bg-gold opacity-50 text-white cursor-pointer';
        arrowColor = 'border-t-gray-500';
        topicType = 'done';
        topicStyle = '';
    } else if (lessonIndex === currentLessonIndex) {
        lessonColor = 'text-black';
        boxStyle = 'bg-gold text-white';
        arrowColor = 'border-t-blue-500';
        topicType = 'current';
        topicStyle = 'cursor-pointer';

        if (!isLastLesson) {
            nextLessonEnabled = completedVideosNum >= lesson.num_videos;
            nextLessonStyle = nextLessonEnabled ? 'bg-blue-500' : 'bg-gray-400';    
        }
    } else {
        lessonColor = 'text-black';
        boxStyle = 'bg-white border border-black cursor-pointer';
        arrowColor = 'border-t-black';
        topicType = 'normal';
        topicStyle = '';
    }

    return (
        <div className={`flex items-start mb-4 ${lessonColor}`}>
            <div className="mr-4 flex flex-col items-center">
                <div 
                    className={`w-12 h-12 ${boxStyle} flex items-center justify-center rounded-md`}
                    onClick={() => onLessonClick && onLessonClick(lessonIndex, false)}
                >
                    {lessonIndex + 1}
                </div>
                {!isLastLesson && (
                    <div className={`w-12 text-center ${arrowColor}`}>⇣</div>
                )}
            </div>
            <div>
                <div className="font-semibold">{lesson.title}</div>
                <div>{lesson.explanation}</div>

                {topicType !== 'done' && lesson.topics && lesson.topics.map((topic, topicIndex) => (
                    <div 
                        key={`lesson1:${topic.id}`} 
                        className={topicStyle} 
                        onClick={() => onTopicClick && onTopicClick(lessonIndex, topicIndex)}
                    >
                        <StudyPlanTopic 
                            topic={topic} 
                            index={topicIndex} 
                            topicType={
                                topicType === 'current'
                                    ? (topicIndex <= currentTopicIndex ? 'current' : 'normal')
                                    : topicType
                            } 
                        />
                    </div>
                ))}

                <div className="flex flex-col items-start sm:flex-row sm:items-center">
                    {onLessonClick && lessonIndex === currentLessonIndex && (
                        <>
                            <div className="flex items-center mr-3">
                                <button 
                                    className={`${nextLessonStyle} text-white py-2 px-4 rounded mt-2`}
                                    disabled={!nextLessonEnabled}
                                    onClick={() => onLessonClick(lessonIndex + 1, false)}
                                >
                                    Next lesson
                                </button>
                            </div>
                            <div className="flex items-center mt-2">
                                {donutChart({ 
                                    percentage: Math.min(100, Math.floor(completedVideosNum / lesson.num_videos * 100))
                                })}
                                <div className="ml-1">{completedVideosNum} video(s) completed</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StudyPlanLesson;
