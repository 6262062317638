import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import lfApi from '../api/lfApi';
import LoadingButton from '../components/LoadingButton';
import VerificationCode from '../components/VerificationCode';
import CheckoutButton from '../components/CheckoutButton';
import { getUserProfile } from '../api/lfApiUser';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

async function createProfile(userProfile) {
    try {
        const response = await lfApi.post('/user/profile', { ...userProfile, do_token_auth: true });
        if (response.status === 200) {
            return response.data;
        }
    }
    catch (error) {
        console.error('Create profile failed: ', error);
    }
    return null;
}

function ProductInfo() {
    return (
        <div className="border border-gray-300 rounded-xl p-2 mb-12">
            <div className="flex gap-3">
                <img src="https://learntofish.app/favicon.ico" alt="Learn to Fish Academy" className="w-6 h-6 m-2" />
                <div className="text-left">
                    <h1 className="font-semibold mr-10 sm:mr-20">Learn to Fish Academy</h1>
                    <div className="text-gray-600">$4.99/month</div>
                    <div className="text-gray-600">after 1 month trial</div>
                </div>
            </div>
        </div>
    );
}

function TrialPage() {
    const [step, setStep] = useState(1); // 1: signup, 2: verification, 3: checkout
    const [userProfile, setUserProfile] = useState({ 
        email: '',
        password: '',
        token: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    function handleChange(e) {
        const { name, value } = e.target;
        setUserProfile(prevState => ({ ...prevState, [name]: value }));
        setError('');
    }

    function hasEmailAndPassword() {
        return userProfile.email && userProfile.password;
    }

    async function handleSignup(event) {
        if (event) event.preventDefault();
        
        if (!hasEmailAndPassword() || isLoading) {
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userProfile.email)) {
            setError('Invalid email address');
            return;
        }

        setIsLoading(true);
        setError('');

        const profile = await createProfile(userProfile);
        if (profile === null) {
            setError('Something went wrong. Please try again later.');
        }
        else if (!profile.success) {
            setError('Email already in use. Please use a different email.');
            setUserProfile(prevState => ({ ...prevState, password: '' }));
        }
        else {
            setStep(2);
        }

        setIsLoading(false);
    }

    async function handleVerification() {
        if (!userProfile.token || isLoading) return;

        setIsLoading(true);
        setError('');

        try {
            const response = await lfApi.post('/auth/login_token', {
                type: 'user',
                username: userProfile.email,
                token: userProfile.token
            });

            if (response.status === 200 && response.data.success) {
                const profile = await getUserProfile();
                if (profile?.data?.user_type === 'premium' || profile?.subscription_action?.action === 'none') {
                    window.location.href = '/';
                } else {
                    setStep(3);
                }
            } else {
                setError('Invalid code, please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
        }

        setIsLoading(false);
    }

    async function handleResendCode() {
        setIsLoading(true);
        setError('');

        try {
            const response = await lfApi.post('/auth/login_cred', {
                type: 'user',
                username: userProfile.email,
                password: userProfile.password
            });

            if (response.status === 200 && response.data.success) {
                setUserProfile(prevState => ({ ...prevState, token: '' }));
            } else {
                setError('Failed to resend code. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again later.');
        }

        setIsLoading(false);
    }

    return (
        <div className="flex mx-auto max-w-screen-lg my-8">
            <div className="inline-flex flex-col items-center border border-gray-300 bg-white rounded-2xl mx-auto py-6 px-4 sm:px-20">
                <ProductInfo />

                {step === 1 && (
                    <>
                        <div className="flex flex-col gap-1 mb-6">
                            <div className="text-lg">First, you'll need to create a parent account</div>
                            <div className="text-xs">Already have an account? <Link to="/login"><span className="text-blue-500 underline">Login</span></Link></div>
                        </div>

                        <form onSubmit={handleSignup} className="flex flex-col">
                            <div>
                                <input
                                    type="email"
                                    className="border border-gray-300 p-1 my-1"
                                    name="email"
                                    value={userProfile.email}
                                    placeholder="Email"
                                    onChange={handleChange} />
                            </div>
                            <div>
                                <input
                                    type="password"
                                    className="border border-gray-300 p-1 my-1"
                                    name="password"
                                    value={userProfile.password}
                                    placeholder="Password"
                                    onChange={handleChange} />
                            </div>

                            {error && <div className="text-red-500 text-sm">{error}</div>}

                            <div className="flex justify-center w-full">
                                <LoadingButton
                                    type="submit"
                                    className={`${hasEmailAndPassword() ? "bg-blue-500" : "bg-gray-400"} text-white py-2 px-4 rounded my-2`}
                                    disabled={!hasEmailAndPassword() || isLoading}
                                    text="Continue"
                                    isLoading={isLoading}
                                    onClick={handleSignup} />
                            </div>
                        </form>
                    </>
                )}

                {step === 2 && (
                    <VerificationCode 
                        email={userProfile.email}
                        token={userProfile.token}
                        onTokenChange={(value) => setUserProfile(prev => ({ ...prev, token: value }))}
                        onResend={handleResendCode}
                        isLoading={isLoading}
                        error={error}
                        onSubmit={handleVerification}
                    />
                )}

                {step === 3 && (
                    <div className="text-center flex flex-col gap-6 items-center">
                        <div className="">
                            Thank you for verifiying your account.
                        </div>

                        <Elements stripe={stripePromise}>
                            <CheckoutButton 
                                text="Proceed to checkout"
                                price_type="monthly_standard"
                                quantity={1} />
                        </Elements>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TrialPage; 