import lfApi from './lfApi';

export async function getUserProfile() {
    try {
        const response = await lfApi.get('/user/profile');
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to fetch user profile');
        }
    }
    catch (error) {
        console.error('Error fetching user profile:', error);
    }

    return null;
} 