import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutButton from './CheckoutButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const FreeTrialStart = ({ subscriptionAction, quantity }) => {
    return (
        <div className="border border-blue-500 border-2 p-4 mb-8 md:w-3/4">
            <div className="mb-4">
                <div className="font-semibold">Unlock your child's potential</div>
                {
                    subscriptionAction.action === 'create_new' && <>
                        <div className="mt-2">
                            Foster your child's curiosity and love for learning with Learn to Fish Academy.
                        </div>
                        <div>
                            Start your first month free.
                        </div>
                    </>
                }
                {
                    subscriptionAction.action === 'create_new_has_canceled' && <div className="mt-2">
                        Please subscribe to create a new child profile.
                    </div>
                }
            </div>
            <div>
                <Elements stripe={stripePromise}>
                    <CheckoutButton 
                        text={subscriptionAction.action === 'create_new' ? 'Start free trial' : 'Subscribe'} 
                        price_type='monthly_standard' 
                        quantity={quantity + 1} />
                </Elements>
            </div>
            {
                subscriptionAction.action === 'create_new' && (
                    <div className="mt-4 text-sm">
                        $4.99/month per child account after free trial
                    </div>
                )
            }
            {
                subscriptionAction.action === 'create_new_has_canceled' && (
                    <div className="mt-4 text-sm">
                        $4.99/month per child account
                    </div>
                )
            }
        </div>
    );
};

export default FreeTrialStart; 