import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import lfApi, { AuthorizationError } from '../api/lfApi';
import { setSubscriptionQuantity, getSubscriptionAction, getAdditionalChargeTotal, getAdditionalSeats } from '../api/lfApiSubscription';
import ChildProfiles from '../components/ChildProfiles';
import ChildProfile from '../components/ChildProfile';
import AddChildProfile from '../components/ChildProfileAdd';
import CheckoutButton from '../components/CheckoutButton';
import LoadingButton from '../components/LoadingButton';
import BillingDetail from '../components/BillingDetail';
import Settings from '../components/Settings';
import { HeaderContext } from '../contexts/HeaderContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

async function getChildProfiles() {
    try {
        const response = await lfApi.get('/user/child');
        if (response.status === 200) {
            return response.data.child_profiles;
        }
        else {
            console.error('Failed to fetch child profiles');
        }
    }
    catch (error) {
        console.error('Error fetching child profiles:', error);
    }

    return [];
}

async function addChildProfile(childProfile) {
    try {
        const response = await lfApi.post('/user/child', childProfile);
        if (response.status === 200) {
            return response.data;
        } 
        else {
            console.error('Failed to add child profile');
        }
    }
    catch (error) {
        console.error('Error adding child profile:', error);
        if (error.response && error.response.status === 403) {
            throw new AuthorizationError('Not authorized');
        }
    }

    return null;
};

function getSubscriptionActionMessage(action, quantity, increaseQuantity) {
    switch (action.action) {
        case 'create_new':
        case 'create_new_has_canceled':
            default:
                return <div className="border border-red-500 border-2 p-4 mb-8">
                    <div className="mb-2">Please subscribe to continue using Learn to Fish Academy</div>
                    <Elements stripe={stripePromise}>
                        <CheckoutButton price_type='monthly_standard' quantity={quantity} />
                    </Elements>
                </div>
        case 'end_trial':
            return 'Your trial needs to be ended. Please subscribe to continue using Learn to Fish Academy';
        case 'increase_quantity':
        case 'increase_quantity_trial':
            return <div className="border border-red-500 border-2 p-4 mb-8">
                <div>
                    You need to add {getAdditionalSeats(action)} to your subscription to continue using Learn to Fish Academy.
                </div>
                <div className="mb-2">
                    This is going to incur {action.action === 'increase_quantity' ? 'an additional charge' : 'a total charge'} of {getAdditionalChargeTotal(action)} in your next billing cycle.
                </div>
                <div className="mb-4 flex justify-center">
                    <BillingDetail subscriptionAction={action} />
                </div>
                <div className="flex justify-center">
                    <LoadingButton text={`Add ${getAdditionalSeats(action)}`} onClick={increaseQuantity} />
                </div>
            </div>;
        case 'decrease_quantity':
            return '';
        case 'update_payment_past_due':
            return <div className="border border-red-500 border-2 p-4 mb-8">
                <div className="mb-4">Your subscription is past due. Please update your payment information.</div>
                <a href={action.billing_portal_url} 
                    target="_blank" rel="noopener noreferrer"
                    className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
                    Pay subscription
                </a>
            </div>;
        case 'update_payment_unpaid':
            return <div className="border border-red-500 border-2 p-4 mb-8">
                <div className="mb-4">Your subscription is unpaid. Please update your payment information.</div>
                <a href={action.billing_portal_url} 
                    target="_blank" rel="noopener noreferrer"
                    className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
                    Pay subscription
                </a>
            </div>;
        case 'none':
            return '';
    }
}

const ParentPage = ({ userProfile, setUserProfile, logout: logoutFunc, subscriptionAction, setSubscriptionAction }) => {
    const [isLoadingChildProfiles, setIsLoadingChildProfiles] = useState(true);
    const [childProfiles, setChildProfiles] = useState([]);
    
    const { setHeaderData } = useContext(HeaderContext);

    const navigate = useNavigate();

    const navigateToParent = () => {
        navigate('');
    };

    const navigateToChildProfile = (username) => {
        navigate(`profile/${username}`);
    };

    const navigateToAddChildProfile = () => {
        navigate('add_profile');
    };

    const logout = () => {
        setChildProfiles([]);
        setHeaderData([]);

        logoutFunc();
    };

    const addSubscriptionSeat = async () => {
        const res = await setSubscriptionQuantity(childProfiles.length);
        if (res && res.success) {
            await fetchSubscriptionAction();
        }
    };

    const fetchChildProfiles = async () => {
        const profiles = await getChildProfiles();
        setChildProfiles(profiles);
        setHeaderData(profiles);

        setIsLoadingChildProfiles(false);
    };

    const fetchSubscriptionAction = async () => {
        const action = await getSubscriptionAction(false);
        if (action && action.action) {
            setSubscriptionAction(action);
        }
    };

    const addAndUpdateChildProfile = async (childProfile) => {
        const profile = await addChildProfile(childProfile);
        if (profile) {
            await fetchChildProfiles();
            await fetchSubscriptionAction();
        }
        return profile;
    };

    const onSelectChildProfile = (username) => {
        navigateToChildProfile(username);
    };

    const onAddChildProfile = () => {
        navigateToAddChildProfile();
    };

    const onAddChildProfileOk = async (childProfile) => {
        const res = await addAndUpdateChildProfile(childProfile);
        if (res) {
            navigateToChildProfile(res.username);
        }
        else {
            navigateToParent();
        }
    };

    const onCancel = () => {
        navigateToParent();
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchChildProfiles();
            await fetchSubscriptionAction();
        };
        fetchData();
    }, []); // The empty array means this effect runs once on mount

    return <div>
        {
            !isLoadingChildProfiles && 
            childProfiles && 
            getSubscriptionActionMessage(subscriptionAction, childProfiles.length, addSubscriptionSeat)
        }

        <Routes>
            <Route path="" element={
                isLoadingChildProfiles
                    ? <div>Loading...</div>
                    : (childProfiles && childProfiles.length > 0 
                        ? <Navigate to={`profile/${childProfiles[0].username}`} />
                        : <ChildProfiles
                            childProfiles={childProfiles} 
                            onSelectProfile={onSelectChildProfile} 
                            onAddProfile={onAddChildProfile} />)
            } />
            <Route path="profile/:username/*" element={
                <ChildProfile 
                    childProfiles={childProfiles} 
                    fetchChildProfiles={fetchChildProfiles} 
                    subscriptionAction={subscriptionAction}
                />
            } />
            <Route path="add_profile" element={
                <AddChildProfile childProfiles={[]} showConsent={true} quantity={childProfiles.length}
                    onOk={onAddChildProfileOk} onCancel={onCancel} />
            } />
            <Route path="setting/*" element={
                <Settings userProfile={userProfile} setUserProfile={setUserProfile} logout={logout} />
            } />
        </Routes>
    </div>;
};

export default ParentPage;
