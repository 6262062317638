import React from 'react';
import { getProfilePicture } from '../api/profile';

const ChildProfiles = ({ childProfiles, onSelectProfile, onAddProfile }) => {
    return (
        <div>
            <h1 className="text-xl text-center mb-4">My kids</h1>

            <div className="flex flex-wrap justify-center gap-4 p-4">
                {childProfiles.map((child) => (
                    <div key={child.id} className="text-center">
                        <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center mx-auto cursor-pointer"
                                onClick={() => onSelectProfile(child.username)}>
                            {getProfilePicture(child, '🐠')}
                        </div>
                        <div className="mt-2">{child.first_name}</div>
                    </div>
                ))}
                <div className="text-center cursor-pointer"
                    onClick={onAddProfile}>
                    <div
                        className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center mx-auto">
                        ＋
                    </div>
                    <div className="mt-2">Add new child</div>
                </div>
            </div>
        </div>
    );
};

export default ChildProfiles;
